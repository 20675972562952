import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import Video from './Video';
import VideoPlay from './VideoPlay';

type Props = {
  isVideoOpen: boolean;
};

const HeroRight = styled('div')<Props>(({ isVideoOpen = false, theme }) => ({
  alignItems: 'center',
  borderRadius: '0.3rem',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: isVideoOpen ? 'none' : '72rem',
  overflow: 'hidden',
  transform: 'translateX(3rem)',
  transition:
    'height 300ms ease-in-out, padding 300ms ease-in-out, width 300ms ease-in-out',
  zIndex: isVideoOpen ? theme.zIndex.welcomeVideo : 0,

  ...(isVideoOpen
    ? {}
    : {
        '&:hover': {
          [Video.toString()]: {
            transform: 'scale(0.95)',
          },

          [VideoPlay.toString()]: {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }),

  [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
    ...(isVideoOpen
      ? {
          left: 0,
          position: 'absolute',
          top: 0,
          transform: 'translate(0, -2rem)',
          width: '100%',
        }
      : {}),
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginTop: '2rem',
    padding: '3rem',
    transform: 'translate(0)',

    ...(isVideoOpen ? { padding: 0 } : {}),
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    display: 'none',
  },
}));

export default HeroRight;
